<template>
  <div>
    <modal-layout
      id="mobile-block-modal"
      size="md"
      :showCloseButton="true"
      :noCloseOnBackdrop="false"
      :noCloseOnESC="false"
    >
      <div class="pre-modal--body gozerian py-0">
        It looks like you're using a mobile device. We recommend using a laptop
        or desktop for the best experience.
      </div>
    </modal-layout>
  </div>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import { isMobileOnly } from "mobile-device-detect";
export default {
  components: { ModalLayout },
  name: "MobileBlockModal",
  mounted() {
    if (isMobileOnly) {
      this.$bvModal.show("mobile-block-modal");
    }
  },
};
</script>
