<template>
  <b-modal
    :id="id"
    centered
    :size="size"
    scrollable
    :no-close-on-esc="noCloseOnESC"
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <template v-slot:modal-header>
      <div class="pre-edge">
        <img src="/assets/images/corner.png" alt="" />
        <img
          class="flipped"
          src="/assets/images/corner.png"
          alt=""
          v-if="!showCloseButton"
        />

        <pnw-button
          variant="primary"
          class="close-button"
          @click="close()"
          v-if="showCloseButton"
        >
          <img src="/assets/images/icons/close.svg" />
        </pnw-button>
      </div>
    </template>
    <slot> </slot>
    <template v-slot:modal-footer>
      <div class="pre-edge">
        <img class="flipped" src="/assets/images/corner.png" alt="" />
        <img src="/assets/images/corner.png" alt="" />
      </div>
    </template>
  </b-modal>
</template>
<script>
import PnwButton from "./PnwButton.vue";
export default {
  components: { PnwButton },
  name: "ModalLayout",
  props: {
    id: {
      default: "modal",
      require: true,
    },
    showCloseButton: {
      default: true,
      require: false,
    },
    size: {
      default: "md",
      require: false,
    },
    noCloseOnESC: {
      default: false,
      require: false,
    },
    noCloseOnBackdrop: {
      default: false,
      require: false,
    },
  },
  methods: {
    close() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  width: 48px;
  height: 48px;
  margin-top: 20px;
  margin-right: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
