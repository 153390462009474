import store from "../store";
import http from "./HttpService";
import jwt from "jsonwebtoken";
const SESSION_NAME = "user";
export function isLoggedIn() {
  const user = getSession();
  if (!user) {
    return null;
  } else {
    const hasToken = getToken();
    if (!hasToken) {
      return null;
    } else {
      return true;
    }
  }
}

//Login Function
export function login(user) {
  return (
    http
      .post("api/pnw/login", user)
      //After Succed
      .then((res, err) => {
        if (res) {
          if (res.data.token) {
            setSession(res.data);
          }
          return res.data;
        } else {
          throw new Error(err);
          //return { message: err.message };
        }
      })
  );
}
export function logout() {
  localStorage.removeItem(SESSION_NAME);
  //Dispatch The State Through The App
  http.post("api/logout", {}).then(
    function (res) {
      return res.data;
    },
    function (err) {
      console.log(err);
    }
  );
  store.dispatch("authenticate");
}

//**************Session & Token Management *****************/
export function updateLocalStorageUserInfo(user) {
  setSession({
    ...JSON.parse(getSession()),
    ...user,
  });
}
export function setSession(user) {
  localStorage.setItem(SESSION_NAME, JSON.stringify(user));
  //Dispatch The Action To The Store
  store.dispatch("authenticate");
}
//Get User Session
export function getSession() {
  return localStorage.getItem(SESSION_NAME);
}
// Extract Token
export function getToken() {
  const user = getSession();
  if (!user) return null;
  const parseObj = JSON.parse(user);
  if (!parseObj) return null;
  const token = parseObj.token;
  if (!token) {
    return null;
  }
  return token;
}
//Return The Values Come From The Token
export function decodeToken() {
  const token = getToken();
  if (!token) {
    return null;
  }
  return jwt.decode(token);
}
//Update Session Token
export function updateSession(newSessionToken) {
  const currentSession = getSession();
  if (currentSession) {
    //window.localStorage.removeItem("user");
    window.localStorage.setItem(SESSION_NAME, JSON.stringify(newSessionToken));
    store.dispatch("authenticate");
  }
}

//Parsing User Object
export function getUserObj() {
  const stringUser = localStorage.getItem(SESSION_NAME);
  const user = JSON.parse(stringUser);
  if (!user) {
    return null;
  }
  return user;
}

const auth = {
  login,
  logout,
  getUserObj,
  updateSession,
  decodeToken,
  getToken,
  getSession,
  updateLocalStorageUserInfo,
  isLoggedIn,
};
export default auth;
