import audioLevel from "../constants/audioConstants";
import store from "../store";

export default function playSound(fileName = "", defaultSoundOn = false) {
  fileName = fileName + (fileName.includes(".wav") ? "" : ".wav");
  if (store.getters.isSoundOn || defaultSoundOn) {
    const audio = new Audio("/assets/waves/" + fileName);
    audio.volume = audioLevel;
    audio.play();
  }
}
