import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const initialStates = {
  data: {
    isAdult: true,
    inProgressing: false,
    ticketCode: "",
    listingId: "",
    ticketType: "",
    selectedActivities: [],
    newProfile: false,
  },
};
const registerStore = {
  namespaced: true,
  state: initialStates,
  mutations: {
    reset(state) {
      state.data = {
        isAdult: true,
        inProgressing: false,
        ticketCode: "",
        listingId: "",
        ticketType: "",
        selectedActivities: [],
        newProfile: false,
      };
    },
    updateOnboardingData(state, data = {}) {
      const newData = { ...state.data, data };
      state.data = {
        ...newData,
        ...data,
      };
    },
  },
  actions: {
    updateOnboardingData({ commit }, payload) {
      commit("updateOnboardingData", payload);
    },
    reset({ commit }) {
      commit("reset", initialStates);
    },
  },
  getters: {
    onboardingData: (state) => {
      return state.data;
    },
  },
};

export default registerStore;
