<template>
  <b-button
    :type="type"
    :size="size"
    :disabled="disabled"
    :class="classes"
    :variant="variant"
    @click="clickEvent"
    @mouseover="hoverEvent"
  >
    <slot></slot>
  </b-button>
</template>
<script>
import audioLevel from "../constants/audioConstants";
import playSound from "../services/AudioService";
export default {
  name: "PnwButton",
  props: {
    type: {
      default: "button",
    },
    size: {
      default: "md",
    },
    disabled: {
      default: false,
    },
    classes: {
      default: "",
    },
    variant: {
      default: "",
    },
  },
  methods: {
    clickEvent($event) {
      playSound("GF-PN1-ButtonClick");
      this.$emit("click", $event);
    },
    hoverEvent($event) {
      playSound("GF-PN1-ButtonHover.wav");
    },
  },
};
</script>
