import Vue from "vue";
import VueRouter from "vue-router";
import MainLayout from "../components/layout/MainLayout";
import routerItems from "../constants/routerItems";
import auth from "../services/AuthService";

Vue.use(VueRouter);

function authNeeded(to, from, next) {
  if (auth.isLoggedIn()) {
    next();
  } else {
    next({
      name: routerItems.LOGIN,
      query: {
        redirect: to.fullPath,
      },
    });
  }
}

function ticketCodeNeeded(to, from, next) {
  let profile = auth.getUserObj()?.user?.profiles[0];
  if (profile && profile.ticketCode) {
    next();
  } else {
    next({
      name: routerItems.TICKET_CODE,
      query: {
        redirect: to.fullPath,
      },
    });
  }
}

function authNotNeeded(to, from, next) {
  if (auth.isLoggedIn()) {
    next({ name: routerItems.LANDING });
  } else {
    next();
  }
}

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "",
        name: routerItems.LANDING,
        component: () => import("@/views/Landing"),
      },
      {
        path: "shop",
        name: routerItems.SHOP,
        component: () => import("@/views/Shop"),
      },
      {
        path: "terms-of-use",
        name: routerItems.TERMS_OF_USE,
        component: () => import("@/views/Main"),
      },
      {
        path: "technical-faqs",
        name: routerItems.TECHNICAL_FAQS,
        component: () => import("@/views/TechnicalFAQs"),
      },
      {
        path: "faqs",
        name: routerItems.FAQS,
        component: () => import("@/views/FAQs"),
      },
      {
        path: "privacy-policy",
        name: routerItems.PRIVACY_POLICY,
        component: () => import("@/views/Main"),
      },
      {
        path: "credits",
        name: routerItems.CREDITS,
        component: () => import("@/views/Credits"),
      },
    ],
  },
  {
    path: "/onboarding",
    component: MainLayout,
    beforeEnter: authNeeded,
    children: [
      {
        path: "",
        redirect: { name: routerItems.TICKET_CODE },
      },
      {
        path: "ticket-code",
        name: routerItems.TICKET_CODE,
        component: () => import("@/views/auth/TicketCode"),
      },
      {
        path: "expedited",
        name: routerItems.EXPEDITED,
        component: () => import("@/views/onboarding/Expedited"),
      },
      {
        path: "welcome",
        name: routerItems.WELCOME,
        component: () => import("@/views/onboarding/Welcome"),
      },
      {
        path: "quiz-landing",
        name: routerItems.QUIZ_LANDING,
        component: () => import("@/views/onboarding/QuizLanding"),
      },
      {
        path: "complete",
        name: routerItems.ONBOARDING_COMPLETE,
        component: () => import("@/views/onboarding/OnboardingComplete"),
      },
    ],
  },
  {
    path: "/auth",
    component: MainLayout,
    beforeEnter: authNotNeeded,
    children: [
      {
        path: "",
        redirect: { name: routerItems.LOGIN },
      },
      {
        path: "login",
        name: routerItems.LOGIN,
        component: () => import("@/views/auth/Login"),
      },
      {
        path: "register",
        name: routerItems.REGISTER,
        component: () => import("@/views/auth/Register"),
      },
      {
        path: "forgot-password",
        name: routerItems.FORGOT_PASSWORD,
        component: () => import("@/views/auth/ForgotPassword"),
      },
    ],
  },
  {
    path: "/",
    component: MainLayout,
    beforeEnter: authNeeded && ticketCodeNeeded,
    children: [
      {
        path: "av-check",
        name: routerItems.AV_CHECK,
        component: () => import("@/views/AVCheck"),
      },
      {
        path: "profile",
        name: routerItems.PROFILE,
        component: () => import("@/views/Profile"),
      },
      {
        path: "about",
        name: routerItems.ABOUT,
        component: () => import("@/views/About"),
      },
      {
        path: "news",
        name: routerItems.NEWS,
        component: () => import("@/views/News"),
      },
      {
        path: "readings",
        name: routerItems.READINGS,
        component: () => import("@/views/Readings"),
      },
      {
        path: "fundraiser",
        name: routerItems.FUNDRAISER,
        component: () => import("@/views/Fundraiser"),
      },
      {
        path: "location",
        name: routerItems.LOCATION,
        component: () => import("@/views/Main"),
      },
      {
        path: "settings",
        name: routerItems.SETTINGS,
        component: () => import("@/views/Settings"),
      },
      {
        path: "select-profile",
        name: routerItems.SELECT_PROFILE,
        component: () => import("@/views/SelectProfile"),
      },
    ],
  },
  { path: "*", component: () => import("@/views/errors/404") },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
