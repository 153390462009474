import moment from "moment";
import "moment-timezone";
import MONTHS from "../constants/dateConstants";

function getMonthNumber(month = MONTHS.January) {
  const months = {
    [MONTHS.January]: 1,
    [MONTHS.February]: 2,
    [MONTHS.March]: 3,
    [MONTHS.April]: 4,
    [MONTHS.May]: 5,
    [MONTHS.June]: 6,
    [MONTHS.July]: 7,
    [MONTHS.August]: 8,
    [MONTHS.September]: 9,
    [MONTHS.October]: 10,
    [MONTHS.November]: 11,
    [MONTHS.December]: 12,
  };
  return months[month];
}
function generateMonthDays(
  month = MONTHS.January,
  year = new Date().getFullYear()
) {
  const lastDay = new Date(year, getMonthNumber(month), 0).getDate();
  const days = [];
  for (let i = 1; i <= lastDay; i++) {
    days.push(i);
  }
  return days;
}
function getShowTime(ticketInfo) {
  if (ticketInfo?.event) {
    const showDate = moment.tz(
      ticketInfo?.event.startTime,
      ticketInfo?.event.timezoneCode
    );

    return showDate.format("hh:mm A") + " " + ticketInfo?.event?.timezoneCode;
  }
  return "-";
}
function getShowDate(ticketInfo) {
  if (ticketInfo?.event) {
    const showDate = moment.tz(
      ticketInfo?.event.startTime,
      ticketInfo?.event.timezoneCode
    );
    return showDate.format("MMM DD, YYYY");
  }
  return "-";
}
function getShowDuration(ticketInfo, unit = "minutes") {
  const now = moment.utc();
  const end = moment.tz(
    ticketInfo?.event.startTime,
    ticketInfo?.event.timezone
  );

  return moment.duration(end.diff(now)).as(unit);
}
const dayHelpers = {
  generateMonthDays,
  getShowDate,
  getShowTime,
  getShowDuration,
};
export default dayHelpers;
