import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Rollbar from "rollbar";
import routerItems from "./constants/routerItems";
import BootstrapVue from "bootstrap-vue";
import VueGtag from "vue-gtag";

import vSelect from "vue-select";
// import FacebookService from "./services/FacebookService";
import VueLazyImageLoading from "vue-lazy-image-loading";
Vue.use(VueLazyImageLoading);
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

const isProduction = process.env.NODE_ENV === "production";
// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
Vue.prototype.$rollbar = new Rollbar({
  accessToken: "42fac08bf29140ecbe4299d0301839f2",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: { environment: "pre-narrative-website" },
});

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
Vue.config.errorHandler = (err, vm) => {
  if (isProduction) {
    vm.$rollbar.error(err);
  }
  throw err; // rethrow
};

Vue.prototype.$routerItems = routerItems;

// FacebookService.initFacebookSdk();

if (isProduction) {
  Vue.use(
    VueGtag,
    {
      config: { id: "UA-6362741-48" },
    },
    router
  );
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
