<template>
  <div>
    <lazy-background
      src="https://firebasestorage.googleapis.com/v0/b/hch-demo.appspot.com/o/background.gif?alt=media&token=e1f7ffa0-a4e8-4083-9ba9-5b0969f97400"
      placeholder="assets/images/landing.gif"
      no-ratio
      :blur="2"
      size="cover"
      class="landing-page"
      v-if="isLanding"
    />
    <div class="app-body">
      <div class="pre-edge">
        <img src="/assets/images/corner.png" alt="" />
        <img class="flipped" src="/assets/images/corner.png" alt="" />
      </div>
      <Header v-if="!isOnboarding && !isHideMenuAndFooter" />
      <main>
        <router-view />
      </main>
      <Footer v-if="!isOnboarding && !isHideMenuAndFooter" />
      <div class="pre-edge">
        <img class="flipped" src="/assets/images/corner.png" alt="" />
        <img src="/assets/images/corner.png" alt="" />
      </div>
      <class-started />
    </div>
    <mobile-block-modal />
    <settings-panel />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import routerItems from "../../constants/routerItems";
import ClassStarted from "../ClassStarted.vue";
import MobileBlockModal from "../MobileBlockModal.vue";
import SettingsPanel from "../SettingsPanel.vue";
import Footer from "./Footer.vue";
import Header from "./Header.vue";
export default {
  components: { Header, Footer, ClassStarted, MobileBlockModal, SettingsPanel },
  name: "MainLayout",
  data() {
    return {
      isLanding: false,
      isOnboarding: false,
    };
  },
  methods: {
    updateBackground() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (this.$route.name === routerItems.LANDING) {
        this.isLanding = true;
      } else {
        this.isLanding = false;
      }
      if (this.$route.name.includes("Onboarding")) {
        this.isOnboarding = true;
      } else {
        this.isOnboarding = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      isHideMenuAndFooter: "isHideMenuAndFooter",
    }),
  },
  mounted() {
    this.updateBackground();
  },
  watch: {
    $route() {
      this.updateBackground();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-body {
  position: relative;
  main {
    min-height: calc(100vh - 278px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.landing-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}
</style>
