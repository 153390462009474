import http from "./HttpService";

async function getTicketInfo(ticketCode) {
  let ticketInfo = null;
  const { data } = await http.get("/api/pnw/ticket-code/" + ticketCode);
  ticketInfo = {
    ticketCode,
    ticketType: data.ticket_type,
    ticketStatus: data.ticket_status,
  };
  if (data.ticket_status === "closed" && data.event) {
    ticketInfo = {
      ...ticketInfo,
      event: {
        listingId: data.event.listing_id,
        listingName: data.event.listing_name,
        showId: data.event.show_id,
        startTime: data.event.start_time,
        timezone: data.event.timezone,
        timezoneCode: data.event.timezone_code,
      },
    };
  }
  return ticketInfo;
}

const voltApiService = {
  getTicketInfo,
};
export default voltApiService;
