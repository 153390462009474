const LANDING = "Landing";
const MAIN = "Main";

const LOGIN = "Login";
const REGISTER = "Register";
const FORGOT_PASSWORD = "ForgotPassword";
const TICKET_CODE = "OnboardingTicketCode";
const ENROLL = "Enroll";

const PROFILE = "Profile";
const LOCATION = "Location";
const SHOP = "Shop";
const FAQS = "FAQs";
const TECHNICAL_FAQS = "TechnicalFAQs";
const CREDITS = "Credits";
const PRIVACY_POLICY = "PrivacyPolicy";
const TERMS_OF_USE = "TermsOfUse";

const ABOUT = "About";
const SETTINGS = "Settings";
const NEWS = "News";
const READINGS = "Readings";
const FUNDRAISER = "Fundraiser";
const SELECT_PROFILE = "SelectProfile";

const PROFILE_CREATE = "ProfileCreate";
const QUESTIONNAIRE = "Questionnaire";
const ID_CARD = "IDCard";
const UPLOAD_IMAGE = "UploadImage";

const CONNECTING = "Connecting";
const PREPARE = "Prepare";

const EXPEDITED = "Expedited";
const WELCOME = "OnboardingWelcome";
const QUIZ_LANDING = "OnboardingQuizLanding";
const ONBOARDING_COMPLETE = "OnboardingComplete";

const AV_CHECK = "AVCheck";

const routerItems = {
  AV_CHECK,

  EXPEDITED,
  WELCOME,
  QUIZ_LANDING,
  ONBOARDING_COMPLETE,

  LANDING,
  MAIN,

  ABOUT,
  NEWS,
  READINGS,
  FUNDRAISER,
  SETTINGS,
  SELECT_PROFILE,

  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  PROFILE_CREATE,
  QUESTIONNAIRE,
  ID_CARD,
  UPLOAD_IMAGE,
  TICKET_CODE,
  ENROLL,

  CONNECTING,
  PREPARE,

  PROFILE,
  LOCATION,
  SHOP,
  FAQS,
  TECHNICAL_FAQS,
  CREDITS,
  PRIVACY_POLICY,
  TERMS_OF_USE,
};

export default routerItems;
