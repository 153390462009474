<template>
  <div class="header">
    <div class="pre-header">
      <router-link :to="{ name: $routerItems.LANDING }"
        ><img src="/assets/images/logo.png"
      /></router-link>
      <pnw-button
        @click="$router.push({ name: $routerItems.LOGIN })"
        v-if="!isAuthenticated && isLanding"
        variant="outline-primary"
      >
        LOG IN
      </pnw-button>
      <pnw-button
        v-if="isAuthenticated && !isTicketPage"
        variant="primary"
        class="menu-toggle-button"
        @click="toggleMenu"
      >
        <img src="/assets/images/icons/menu.svg" />
      </pnw-button>
    </div>
    <div class="sidebar-menu" :class="{ show: showMenu }">
      <div class="menu-header">
        <pnw-button
          variant="primary"
          class="menu-toggle-button"
          @click="toggleMenu"
        >
          <img src="/assets/images/icons/close.svg" />
        </pnw-button>
      </div>
      <div class="menu-body">
        <router-link :to="{ name: $routerItems.PROFILE }" class="menu-item"
          >PROFILE</router-link
        >
        <router-link
          :to="{ name: $routerItems.ABOUT }"
          class="menu-item pre-nav--item"
          >ABOUT</router-link
        >
        <router-link :to="{ name: $routerItems.NEWS }" class="menu-item"
          >NEWS</router-link
        >
        <router-link :to="{ name: $routerItems.READINGS }" class="menu-item"
          >READING LIST</router-link
        >
<!--        <router-link :to="{ name: $routerItems.FUNDRAISER }" class="menu-item"-->
<!--          >FUNDRAISER</router-link-->
<!--        >-->
        <a href="https://shop.ghostbusters-paranormal.institute/" target="_blank" class="menu-item">
          SHOP
        </a>

        <div class="menu-bottom">
          <span class="menu-item" @click="logout">LOG OUT</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import routerItems from "../../constants/routerItems";
import auth from "../../services/AuthService";
import PnwButton from "../PnwButton.vue";
export default {
  name: "Header",
  components: { PnwButton },
  data() {
    return {
      showMenu: false,
      isLanding: false,
      isTicketPage: false,
    };
  },
  methods: {
    changedRoute() {
      if (
        this.$route.name === routerItems.LANDING ||
        this.$route.name === routerItems.REGISTER
      ) {
        this.isLanding = true;
      } else {
        this.isLanding = false;
      }
      this.isTicketPage = this.$route.name === routerItems.TICKET_CODE;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.toggleMenu();
      auth.logout();
      this.$router.push({ name: this.$routerItems.LANDING });
    },
  },
  mounted() {
    this.changedRoute();
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
  },
  watch: {
    $route() {
      this.showMenu = false;
      this.changedRoute();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/colors";
.header {
  .menu-toggle-button {
    width: 48px;
    height: 48px;
    padding: 4px;
  }
  .sidebar-menu {
    background-color: #000;
    border-left: 1px solid #6bca76;
    position: fixed;
    z-index: 1000;
    width: 360px;
    bottom: 0;
    top: 0;
    right: 0;
    right: -360px;
    transition: right 0.3s ease;
    &.show {
      right: 0;
    }
    .menu-header {
      display: flex;
      justify-content: flex-end;
      padding: 24px;
      border-bottom: 0.8px dotted #6bca76;
    }
    .menu-item {
      display: block;
      height: 32px;
      padding: 0 32px;
      font-size: 24px;
      line-height: 28.8px;
      font-weight: 700;
      color: $accent-03;
      margin-bottom: 32px;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
    .menu-body {
      padding: 32px 0;
    }
    .menu-bottom {
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
