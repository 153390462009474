<template>
  <div class="settings-panel">
    <b-button
      :variant="isSoundOn ? 'primary' : 'outline-primary'"
      class="setting-btn"
      @click="toggleSound()"
    >
      <img
        src="/assets/images/icons/sound-off.svg"
        v-if="!isSoundOn"
        class="sound-img"
      />
      <img
        src="/assets/images/icons/sound-on.svg"
        v-if="isSoundOn"
        class="sound-img"
      />
    </b-button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import playSound from "../services/AudioService";
export default {
  name: "SettingsPanel",
  methods: {
    ...mapActions({
      toggleSoundOnOff: "toggleSoundOnOff",
    }),
    toggleSound() {
      playSound("GF-PN1-Audio" + (this.isSoundOn ? "Off" : "On"), true);
      this.toggleSoundOnOff();
    },
  },
  computed: {
    ...mapGetters({
      isSoundOn: "isSoundOn",
    }),
  },
};
</script>
<style lang="scss" scoped>
.settings-panel {
  position: fixed;
  padding: 10px;
  background-color: black;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 999;
  right: 0;
  bottom: 20px;
  .setting-btn {
    padding: 8px 12px;
    .sound-img {
      width: 24px;
    }
  }
}
</style>
