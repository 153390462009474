const January = "January";
const February = "February";
const March = "March";
const April = "April";
const May = "May";
const June = "June";
const July = "July";
const August = "August";
const September = "September";
const October = "October";
const November = "November";
const December = "December";

const MONTHS = {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
};

export const MONTH_NUMBERS = {
  [January]: 0,
  [February]: 1,
  [March]: 2,
  [April]: 3,
  [May]: 4,
  [June]: 5,
  [July]: 6,
  [August]: 7,
  [September]: 8,
  [October]: 9,
  [November]: 10,
  [December]: 11,
};
export default MONTHS;
