import Vue from "vue";
import Vuex from "vuex";
import auth, { setSession } from "../services/AuthService";
import registerStore from "./registerStore";
import http from "../services/HttpService";
import { startSoonLimit } from "../constants/showsConstants";
import dayHelpers from "../utils/dayHelper";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    ticketInfo: null,
    user: null,
    authToken: "",
    soundOn: true,
    isHideMenuAndFooter: false,
  },
  mutations: {
    setHideMenuAndFooter(state, hide) {
      state.isHideMenuAndFooter = hide;
    },
    toggleSoundOnOff(state) {
      state.soundOn = !state.soundOn;
    },
    authenticate(state) {
      state.isAuthenticated = auth.isLoggedIn();
      if (state.isAuthenticated) {
        const user = auth.getUserObj();
        state.user = user.user;
        state.authToken = user.token;
      } else {
        state.user = null;
      }
    },
    addProfile(state, profile) {
      state.user.profiles = [...state.user.profiles, profile];
    },
    setProfile(state, profile) {
      state.currentProfile = profile;
    },
    setTicketInfo(state, ticketInfo) {
      state.ticketInfo = ticketInfo;
    },
  },
  actions: {
    authenticate({ commit }) {
      commit("authenticate");
    },
    async toggleSoundOnOff({ commit }) {
      commit("toggleSoundOnOff");
    },
    async addProfile({ commit }, payload) {
      const { data: { user } = {} } = await http.post("api/profiles", payload);
      setSession({ token: auth.getToken(), user });
    },
    async setProfile({ commit }, profileId) {
      const { data: { user } = {} } = await http.post("api/switch-profile", {
        profileId,
      });
      setSession({ token: auth.getToken(), user });
    },
    async removeProfile({ commit }, profileId) {
      const { data: { user } = {} } = await http.delete(
        "api/profiles/" + profileId
      );
      setSession({ token: auth.getToken(), user });
    },
    async updateProfile({ commit }, { profileId, profile } = {}) {
      const { data: { user } = {} } = await http.put(
        "api/profiles/" + profileId,
        profile
      );
      setSession({ token: auth.getToken(), user });
    },
    async saveTicketInfo({ commit }, { profileId, ticketInfo } = {}) {
      let showId;
      let name = ticketInfo.event?.listingName;
      if (name && name.includes("/")) {
        // show id is everything after the last forward slash
        showId = name.substr(name.lastIndexOf("/") + 1);
      }
      const { data: { user } = {} } = await http.put(
        "api/profiles/" + profileId,
        {
          ticketCode: ticketInfo.ticketCode || "",
          ticketType: ticketInfo.ticketType || "",
          listingId: showId,
        }
      );
      setSession({ token: auth.getToken(), user });
      commit("setTicketInfo", ticketInfo);
    },
    updateTicketInfo({ commit }, ticketInfo) {
      commit("setTicketInfo", ticketInfo);
    },
    setHideMenuAndFooter({ commit }, hide = true) {
      commit("setHideMenuAndFooter", hide);
    },
  },
  getters: {
    isHideMenuAndFooter: (state) => {
      return state.isHideMenuAndFooter;
    },
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
    currentUser: (state) => {
      return state.user;
    },
    currentAuthToken: (state) => {
      return state.authToken;
    },
    currentProfile: (state) => {
      return state.user?.profiles?.find(
        (item) => item.id === state.user.currentProfileId
      );
    },
    userProfiles: (state) => {
      return state.user?.profiles || [];
    },
    ticketInfo: (state) => {
      return state.ticketInfo;
    },
    isSoundOn: (state) => {
      return state.soundOn;
    },
    isStartSoon: (state) => {
      if (state.ticketInfo?.event) {
        const duration = dayHelpers.getShowDuration(
          state.ticketInfo,
          "minutes"
        );
        if (duration >= 0 && duration < startSoonLimit) {
          return duration;
        } else {
          return duration;
        }
      }
      return null;
    },
  },
  modules: {
    registerStore: registerStore,
  },
});
