<template>
  <footer class="pre-footer">
    <div class="pre-links">
      <router-link
        class="pre-links--item"
        v-if="isAuthenticated"
        :to="{ name: $routerItems.PROFILE }"
      >
        Profile
      </router-link>
      <a href="https://shop.ghostbusters-paranormal.institute/" target="_blank" class="pre-links--item">
        Shop
      </a>
      <router-link class="pre-links--item" :to="{ name: $routerItems.FAQS }">
        General FAQs
      </router-link>
      <router-link
        class="pre-links--item"
        :to="{ name: $routerItems.TECHNICAL_FAQS }"
      >
        Technical FAQs
      </router-link>
      <router-link
        class="pre-links--item"
        :to="{ name: $routerItems.LOGIN }"
        v-if="!isAuthenticated"
      >
        Login
      </router-link>
      <router-link
        v-if="!isAuthenticated"
        class="pre-links--item"
        :to="{ name: $routerItems.REGISTER }"
      >
        Register
      </router-link>
    </div>
    <div class="pre-links">
      <a
        href="https://www.secretcinema.org/my-data"
        target="_blank"
        class="pre-links--item"
      >
        My Data
      </a>
      <a
        href="https://www.secretcinema.org/privacy-policy"
        target="_blank"
        class="pre-links--item"
      >
        Privacy Policy
      </a>
      <a
        href="https://www.secretcinema.org/privacy-settings"
        target="_blank"
        class="pre-links--item"
      >
        Privacy Settings
      </a>
      <a
        href="https://www.secretcinema.org/terms-conditions"
        target="_blank"
        class="pre-links--item"
      >
        Terms and Conditions
      </a>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
  },
};
</script>
<style lang="scss" scoped>
footer {
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  padding: 32px;
  height: 80px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  .social-links {
    display: flex;
    a {
      font-size: 12px;
      line-height: 11px;
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
