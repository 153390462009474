import axios from "axios";
import auth from "./AuthService";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL || "http://localhost:8080",
  timeout: 10000,
  params: {},
});

http.interceptors.request.use(
  (config) => {
    const token = auth.getToken();
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
