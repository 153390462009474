<template>
  <div>
    <div
      class="pre-banner"
      v-if="isAuthenticated && isStartSoonShow && !isAVCheckPage"
    >
      <div class="pre-banner--copy">
        <div class="pre-subhead">Class has started!</div>
        <div class="pre-classtime">
          <div class="pre-classtime--block">
            <img
              class="pre-classtime--icon"
              src="/assets/images/calendar.png"
              alt=""
            />
            <div class="pre-classtime--copy">{{ startDate }}</div>
          </div>
          <div class="pre-classtime--block">
            <img
              class="pre-classtime--icon"
              src="/assets/images/time.png"
              alt=""
            />
            <div class="pre-classtime--copy">{{ startTime }}</div>
          </div>
        </div>
      </div>
      <div class="pre-banner--btns">
        <div class="pre-btns">
          <img
            class="pre-btn--lights"
            src="/assets/images/knightrider.png"
            alt=""
          />
          <pnw-button variant="primary" size="xl" @click="enterNow">
            Enter Now
          </pnw-button>
          <img
            class="pre-btn--lights flipped"
            src="/assets/images/knightrider.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import routerItems from "../constants/routerItems";
import { startSoonLimit, showRunTime } from "../constants/showsConstants";
import voltApiService from "../services/VoltApiService";
import PnwButton from "./PnwButton.vue";
import dayHelpers from "../utils/dayHelper";

export default {
  components: { PnwButton },
  name: "ClassStarted",
  data() {
    return {
      startDate: "",
      startTime: "",
      isAVCheckPage: false,
      isStartSoonShow: false,
    };
  },
  methods: {
    ...mapActions({
      updateTicketInfo: "updateTicketInfo",
    }),
    checkRoute() {
      this.isAVCheckPage =
        this.$route.name === routerItems.AV_CHECK ||
        this.$route.name === routerItems.TICKET_CODE ||
        this.$route.name === routerItems.EXPEDITED;
    },
    enterNow() {
      const domain = process.env.VUE_APP_GHOSTBUSTER;
      window.open(
        `${domain}/#/join-from-pnw/${this.currentProfile.id}/${this.currentAuthToken}`,
        "_blank"
      );
    },
    async loadInfo() {
      if (this.currentProfile?.ticketCode && !this.ticketInfo) {
        const ticketInfo = await voltApiService.getTicketInfo(
          this.currentProfile?.ticketCode
        );
        this.updateTicketInfo(ticketInfo);
      }
      this.timer = setInterval(() => {
        if (this.ticketInfo?.event) {
          const duration = dayHelpers.getShowDuration(this.ticketInfo);
          if (duration < startSoonLimit && !this.classFinished) {
            this.isStartSoonShow = true;
            if (this.isStartSoon) {
              this.startDate = dayHelpers.getShowDate(this.ticketInfo);
              this.startTime = dayHelpers.getShowTime(this.ticketInfo);
            }
          } else {
            this.isStartSoonShow = false;
          }
        } else {
          this.startDate = "-";
          this.startTime = "-";
        }
      }, 1000);
    },
  },
  mounted() {
    this.loadInfo();
    this.checkRoute();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      currentProfile: "currentProfile",
      currentAuthToken: "currentAuthToken",
      ticketInfo: "ticketInfo",
      isStartSoon: "isStartSoon",
    }),
    classFinished() {
      return Math.abs(dayHelpers.getShowDuration(this.ticketInfo))  >  showRunTime;
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
};
</script>
