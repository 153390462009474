<template>
  <div>
    <router-view></router-view>
    <audio id="background_audio" autoplay="autoplay" loop>
      <source src="assets/waves/GF-PN1-Atmos.wav" type="audio/wav" />
    </audio>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ScTicketing",
  data() {
    return {
      audio: null,
    };
  },
  mounted() {
    this.$store.dispatch("authenticate");
    if (process.env.NODE_ENV === "production") {
      this.$rollbar.info("Sc Ticketing App started.");
    }

    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll(event) {},
  },
  computed: {
    ...mapGetters({
      isSoundOn: "isSoundOn",
    }),
  },
  watch: {
    isSoundOn(newValue) {
      document.getElementById("background_audio").muted = !newValue;
    },
  },
  destroyed() {
    if (this.audio) {
      this.audio.removeEventListener("ended", this.audioHandler);
    }
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss">
@import "./assets/scss/styles.scss";
</style>
